<template>
  <page-layout linkDefault="/chats" :title="'chat'" :backButton="true">
    <Loading v-if="chat_previewed?.participants?.length === 0 || !user" />
    <div class="relative bg-white" v-else-if="chat_previewed.participants.length > 0 && user">
      <div class="
          h-36
          bg-white
          border-b
          border-[#000000aa]
          p-6
          flex
          items-end
          justify-start
          fixed
          top-0
          left-0
          right-0
          z-50
        ">
        <div class="flex items-center">
          <img :src="user.img_url" alt="" class="w-14 h-14 rounded-full" />
          <div class="mx-4">
            <div class="text-2xl font-bold font-poppins truncate capitalize">
              {{ user.name }}
            </div>
            <p class="text-orange text-sm -mt-1">@{{ user.username }}</p>
            <!-- <div class="flex items-center">
              <div class="w-3 h-3 rounded-full"
                :class="{ 'bg-green-400': online(recipient), 'bg-gray-300': !online(recipient) }"></div>
              <p class="font-extralight mx-2 text-xs pb-1 font-poppins">
                <span v-if="online(recipient)">online</span>
                <span v-else>Last active: {{ lastActive(recipient) }}</span>
              </p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="p-4 pt-24 w-full h-full overflow-y-scroll bg-white">
        <!-- <div class="relative h-4 bg-transparent flex justify-center"> -->
        <!-- <p
            class="
              fixed
              z-30
              top-40
              text-shark text-opacity-75
              bg-gray-300
              px-3
              py-1
              font-bold
              text-xs
              rounded-full
              mx-auto
              max-w-min
            "
          >
            {{ previewed_message_date }}
          </p> -->
        <!-- </div> -->
        <div v-for="(message, index) in messages" :key="index" class="message">
          <p class="
                my-2                
                z-30
                text-shark text-opacity-75
                bg-gray-300
                px-3
                py-1
                font-bold
                text-xs
                rounded-full
                mx-auto
                max-w-min
                font-poppins
              " v-if="!messages[index - 1] || date(messages[index - 1].timestamp) != date(messages[index].timestamp)">
            {{ date(messages[index].timestamp) }}
          </p>
          <message :timestamp="
            messages[index + 1]
              ? timeExempt(message.timestamp, messages[index + 1].timestamp)
              : message.timestamp
          " :right="message.sender != this.user._id" :left="message.sender == this.user._id">
            <div v-if="message.type == 'session_accept'" class="pb-8">
              <p class="font-bold font-poppins mb-4">
                session request accepted!
              </p>
              <button class="text-white font-bold bg-orange px-5 py-2 rounded-full   float-right"
                @click="this.$router.push(`/sessions/${message.session._id}`)">view
                confirmation</button>
            </div>
            <div v-else-if="message.type == 'session_reject'" class="pb-8">
              <p class="font-bold font-poppins mb-4">
                session request rejected
              </p>
              <button class="text-white font-bold bg-orange px-5 py-2 rounded-full   float-right"
                @click="this.$router.push(`../../results?class=${message.session.course.class.desc}`)">browse more
                {{ message.session.course.class.desc }} tutors</button>
            </div>
            <div v-else-if="message.type == 'payment'">
              <p class="text-lg font-bold text-orange float-right">{{ message.session.payment_amount }}</p>
              <p class="font-bold font-poppins">
                payment sent
              </p>
            </div>
            <div v-else-if="message.session" class="pb-8">
              <p class="font-bold font-poppins mb-4">
                session request for
                <span class="
                      uppercase
                      text-orange
                      font-bold
                      px-3
                      py-1
                      bg-orange-tint
                      rounded-lg
                      font-poppins
                      cursor-pointer
                    " @click="
                      this.$router.push(
                        `../../results?class=${message.session.course.class.desc}`
                      )
                    ">
                  {{ message.session.course.class.desc }}
                </span>
                on
                <span class="font-bold text-orange font-poppins">{{
                  message.session.slot.day
                }}</span>
                @<span class="font-bold text-orange font-poppins">{{
                  time(message.session.slot.time)
                }}</span>
              </p>
              <button class="font-poppins text-white font-bold bg-orange px-5 py-2 rounded-full   float-right"
                @click="this.$router.push(`/sessions/${message.session._id}`)">view</button>
            </div>
            <p v-else class="font-poppins">
              {{ message.text }}
            </p>
          </message>
        </div>
        <div class="h-3 mt-20" id="chatBottom" ref="chatBottom" key="bottom"></div>
      </div>
      <div class="fixed bottom-0 left-0 right-0 bg-white border-t
      border-[#000000aa] pt-4 pb-12 px-4">
        <form @submit.prevent="send" class="
            w-full
            mx-auto
            relative
            bg-white
            flex
            justify-between
            items-center
            border-2 border-orange
            rounded-xl
            px-2
          ">
          <input type="text" name="message-input" :value="message" class="
              appearance-none
              outline-none
              focus:text-orange
              truncate
              transition
              duration-300
              ease-in-out
              w-60
              p-2
              font-poppins
              bg-transparent
            " placeholder="send a message" @input="message = $event.target.value" />
          <transition name="scale-up" appear class="relative">
            <button type="submit" v-if="message != ''" class="
                font-bold
                px-4
                py-1
                text-white
                rounded-lg
                cursor-pointer
                transition
                bg-orange
                capitalize
                font-poppins
              ">
              send
            </button>
          </transition>
        </form>
      </div>
    </div>
  </page-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useRoute } from "vue-router";
import formInput from "../components/formInput.vue";
import Message from "../components/message.vue";
import PageLayout from "../components/base/pageLayout.vue";
import { relative_time, time_12, date_month_day } from "../helpers/parser";
import { getUserById } from "../helpers/getters";
import Loading from "../components/Loading.vue"
export default {
  components: { formInput, Message, PageLayout, Loading },
  setup() {
    const route = useRoute();
    const chat_id = route.params.id;
    return { chat_id };
  },
  data() {
    return {
      typing: false,
      message: "",
      scrollDown: -1,
      user: null,
      loading: true,
      participants: [],
    };
  },
  computed: {
    ...mapState(["chat_previewed", "messages"]),
    recipient() {
      return this.participants[0] == this.$store.state.user._id ? this.user : this.$store.state.user
    },
  },
  ionViewWillEnter() {
    this.load();
  },
  ionViewWillLeave() {
    this.exit_chat(this.chat_id)
    this.user = null;
  },
  methods: {
    ...mapActions(["send_message", "get_chat", "exit_chat"]),
    async load() {
      await this.get_chat(this.chat_id);
      this.participants = this.chat_id.split(":").filter(id => !!id)
      const otherUserId = this.participants[0] == this.$store.state.user._id ? this.participants[1] : this.participants[0]
      this.user = (await getUserById(otherUserId)).data;
      this.loading = false;
      this.scrollDown = setInterval(() => { this.scrollToBottom() }, 10000);
    },
    async send() {
      const message = {
        text: this.message,
        chat_id: this.chat_id,
      };
      this.message = "";
      await this.send_message(message);
    },
    time(time) {
      return time_12(time);
    },
    date(timestamp) {
      return date_month_day(timestamp);
    },
    timeExempt(time, nextTime) {
      if (nextTime - time < 100000) return null;
      else return time;
    },
    online(recipient) {
      let last = new Date(recipient.lastActive);
      let now = Date.now()
      return Math.abs(now - last) < 100000;
    },
    lastActive(recipient) {
      return relative_time(recipient.lastActive);
    },
    scrollToBottom() {
      let messages = document.getElementsByClassName("message")
      if (messages && messages.length > 12 && this.scrollDown != -1) {
        let bottom = document.getElementById("chatBottom")
        bottom.scrollIntoView({
          behavior: "smooth", // or "auto" or "instant"
          block: "start", // or "end"
        });
        clearInterval(this.scrollDown)
        this.scrollDown = -1
      }
    },
  },
  watch: {
    '$router.route.id': async function () {
      this.user = null;
      await this.load();
    },
    messages: function () {
      this.scrollDown = setInterval(() => { this.scrollToBottom() }, 1000);
    },
  }
};
</script>